import { NODE_TYPE_ITEM } from '../../types/nodeTypes';
import DefaultCatalogConfig, { CatalogConfigProps } from './DefaultCatalogConfig';

export default class BoxCatalogConfig extends DefaultCatalogConfig {
  constructor(props: CatalogConfigProps) {
    super(props);
    this.editable = props.isBoxEditor;

    this.category.autoCategorisable = false;
    this.category.rename = props.isBoxEditor;
    this.category.delete = props.isGlobalMarket && props.isBoxEditor;
    this.category.comment = props.isBoxEditor;
    this.category.import = false;
    this.category.publish = props.isBoxEditor;
    this.category.hide = props.isBoxEditor;
    this.category.translation = props.isBoxEditor;
    this.category.levelRestriction = true;
    this.category.levelEditable = [1];
    this.category.images = props.isBoxEditor;

    this.catalog.availableForMarkets = props.isGlobalMarket && props.isBoxEditor;
    this.catalog.translation = !props.isGlobalMarket && props.isBoxEditor;

    this.allowRanking = props.isBoxEditor;
    this.newCategory = props.isBoxEditor;
    this.allowCutCategory = false;
    this.allowItemOperations = props.isBoxEditor;
    this.allowCopyCategory = props.isBoxEditor;
    this.moveDisabled = false;
    this.allowHoverActions = props.isBoxEditor;

    this.canCopy = (node) => {
      if (node.level === 1) return false;

      return true;
    };

    this.canPaste = ({ nextParent, node }) => {
      if (!this.editable) return false;

      if (node?.type === NODE_TYPE_ITEM) {
        return true;
      }
      return nextParent?.level === 1;
    };
  }
}
