import { canPasteNode } from '../../../components/Tree/helpers/canPasteHandlers';
import { NodeBase } from '../../../entities/Node';

export type CatalogConfigProps = {
  catalogId: string;
  productsCatalogId: string;
  productsDraftCatalogId?: string;
  isEditorForCurrentMarket: boolean;
  isGlobalEditor: boolean;
  isGlobalMarket: boolean;
  isBoxEditor: boolean;
};

export default class DefaultCatalogConfig {
  allowedSources;

  allowHoverActions = false;

  allowCopyCategory = false;

  allowCutCategory = false;

  allowItemOperations = false;

  allowRanking = false;

  editable = false;

  indentTree = false;

  moveDisabled = true;

  newCategory = false;

  onlyExpandSearchedNodes = true;

  isBoxEditor = false;

  isEditorForCurrentMarket = false;

  catalog = {
    translation: false,
    availableForMarkets: false,
    createDraft: false,
    activateDraft: false,
  };

  // category details actions
  category: {
    levelRestriction: boolean;
    levelEditable?: number[];
    hide: boolean;
    rename: boolean;
    translation: boolean;
    comment: boolean;
    delete: boolean;
    publish: boolean;
    autoCategorisable: boolean;
    import: boolean;
    categoryType: boolean;
    images: boolean;
  } = {
    levelRestriction: false,
    hide: false,
    rename: false,
    translation: false,
    comment: false,
    delete: false,
    publish: false,
    autoCategorisable: false,
    import: false,
    categoryType: false,
    images: false,
  };

  constructor({
    catalogId,
    productsCatalogId,
    isEditorForCurrentMarket,
    isGlobalMarket,
  }: CatalogConfigProps) {
    this.editable = isGlobalMarket && isEditorForCurrentMarket;

    this.category = {
      autoCategorisable: this.editable,
      categoryType: false,
      comment: isEditorForCurrentMarket,
      delete: isGlobalMarket && isEditorForCurrentMarket,
      import: this.editable,
      images: isEditorForCurrentMarket,
      hide: isEditorForCurrentMarket,
      levelRestriction: false,
      publish: this.editable,
      rename: this.editable,
      translation: isEditorForCurrentMarket,
    };

    this.indentTree = this.editable;

    this.allowedSources = [catalogId];
    if (catalogId !== productsCatalogId) {
      this.allowedSources.push(productsCatalogId);
    }

    this.canPaste = this.canPaste.bind(this);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canCopy(node: NodeBase) {
    return true;
  }

  canPaste(eventArgs: { nextParent: NodeBase; node: NodeBase }) {
    if (
      !(this.editable || this.allowRanking) ||
      !this.allowedSources.includes(eventArgs?.node?.catalogId)
    ) {
      return false;
    }
    return canPasteNode(eventArgs, this.moveDisabled);
  }
}
